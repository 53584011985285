const TRANSLATIONS = {
  de: {
    euro: "€",
    kWh: "kWh",
    next: "Weiter",
    back: "Zurück",
    brutto: "Brutto",
    netto: "Netto",
    ca: "ca. ",
    per_month: " pro Monat",

    card_statefunding_title: "Unterstützung durch {{UNIT}}-Preisbremse",
    card_statefunding_title_short: "{{UNIT}}-Preisbremse",
    card_statefunding_description: "Zur Abmilderung der gestiegenen {{UNIT}}preise hat die Bundesregierung verschiedene Maßnahmen beschlossen. Eine davon ist die sogenannte {{UNIT}}-Preisbremse. Diese sorgt dafür, dass ein großer Anteil des benötigten {{UNIT}}es bis Dezember 2023 für Kundinnen und Kunden nur zu einem niedrigeren Betrag bezogen wird. Die Differenz zum eigentlichen Tarifpreis übernimmt der Staat. Folgend zeigen wir Ihnen, wie sich die {{UNIT}}-Preisbremse bei Ihnen auswirkt. Bitte beachten Sie, dass es sich bei den Beträgen um Näherungswerte auf Basis des letzten Verbrauchs handelt. Diese können vom tatsächlichen Wert abweichen.",
    card_statefunding_description__waerme: "Zur Abmilderung der gestiegenen Wärmepreise hat die Bundesregierung verschiedene Maßnahmen beschlossen. Eine davon ist die sogenannte Wärme-Preisbremse. Diese sorgt dafür, dass ein großer Anteil der benötigten Wärme bis Dezember 2023 für Kundinnen und Kunden nur zu einem niedrigeren Betrag bezogen wird. Die Differenz zum eigentlichen Tarifpreis übernimmt der Staat. Folgend zeigen wir Ihnen, wie sich die Wärme-Preisbremse bei Ihnen auswirkt. Bitte beachten Sie, dass es sich bei den Beträgen um Näherungswerte auf Basis des letzten Verbrauchs handelt. Diese können vom tatsächlichen Wert abweichen.",

    description_step1: "Mit unserem {{UNIT}}-Einsparrechner kalkulieren Sie anhand weniger Basisdaten die Höhe Ihrer {{UNIT}}kosten im laufenden Jahr und können die Auswirkungen ausgewählter Sparmaßnahmen erproben. Hinweis: Alle Beträge sind Brutto-Beträge. Bitte beachten Sie, dass die errechneten Beträge unverbindlich sind und lediglich der Orientierung dienen.",
    description_step2: "",
    description_step3: "Bei den folgenden Einsparungen durch Maßnahmen handelt es sich i.d.R. um Durchschnittswerte, die auf langjähriger Erfahrung beruhen. Diese können jedoch im Einzelfall, je nach individuellem energetischem Zustand Ihrer Wohnung/Ihres Hauses, abweichen. Bedenken Sie zudem: Den größten Spar-Erfolg erzielen Sie meist dort, wo Sie bislang noch keine Sparanstrengungen unternommen haben! Für die konkret errechnete Summe können wir deshalb keine Gewähr übernehmen. Es handelt sich um Näherungswerte.",
    last_annual_statement: "Ihre letzte {{UNIT}}-Abrechnung",
    last_annual_statement_short: "Abrechnung & Verbrauch",
    current_consumtion: "Ihr letzter Verbrauch (kWh / Jahr)",
    current_consumtion_info: "Um den exakten Wert der Soforthilfe bzw. für die Entlastung im Bereich {{UNIT}} und Fernwärme zu berechnen, benötigen wir den Prognosewert, den wir für September 2022 angelegt haben. Dieser Wert ist von Kunde zu Kunde und von Region zu Region unterschiedlich. Aus Gründen der Vereinfachung haben wir uns entschieden, hier als Näherungswert den letztjährigen Verbrauch anzulegen. Aus diesem Grund können die tatsächlichen Werte leicht abweichen.",
    current_consumtion_limit: "Bitte beachten Sie, dass der Rechner nur bis zu einem {{UNIT}}verbrauch von 1,5 Mio. kWh {{UNIT}} gilt. Bei höheren Verbräuchen gelten die Regelungen für Vielverbraucher und Unternehmen.",
    current_base_price: "Ihr letzter Grundpreis (€ / Jahr)",
    current_working_price: "Ihr letzter Arbeitspreis (ct / kWh)",
    current_price_total: "Ihre letztjährigen {{UNIT}}-Kosten",
    current_price_total_euro: "Ihre letztjährigen {{UNIT}}-Kosten (€)",
    current_price_total_known: "Kennen Sie Ihre letztjährigen {{UNIT}}-Kosten?",
    new_price: "Preise nach Preisanpassung",
    new_base_price: "Aktueller Grundpreis (€ / Jahr)",
    new_working_price: "Aktueller Arbeitspreis (ct / kWh)",
    new_price_total: "Neue {{UNIT}}-Jahreskosten",
    new_price_difference: "Mehrkosten zum Vorjahr",
    state_funding_dec22: "Dezember-Soforthilfe",
    state_funding_dec22_tooltip: "Es handelt sich um einen Näherungswert, konkret ein Zwölftel der Verbrauchsprognose für das kommende Jahr. Exakt ergibt sich der Wert aus der „Multiplikation von einem Zwölftel des Jahresverbrauchs, den der {{UNIT}}lieferant für die Entnahmestelle im Monat September 2022 prognostiziert hat, mit dem Arbeitspreis in Cent pro Kilowattstunde, der zum Stichtag 1. Dezember für den Monat Dezember 2022 im jeweiligen Lieferverhältnis vereinbart ist“. Diese Entlastung wird für den Monat Dezember 2022 gutgeschrieben und spätestens in ihrer Jahresabrechnung berücksichtigt. Hinweis: Der Prognosewert entspricht nicht unbedingt dem Wert des Verbrauchs der letzten Abrechnung.",
    state_funding_23: "Staatliche Entlastung",
    state_funding_23_tooltip: "Platzhalter",
    state_funding_23_tooltip__gas: "Die Gas-Preisbremse gilt von März 2023 bis Dezember 2023, wird aber auch rückwirkend auf Januar und Februar angewendet. Private Haushalte sowie kleine und mittlere Unternehmen mit einem Gas- und Wärmeverbrauch bis maximal 1,5 Millionen Kilowattstunden im Jahr müssen so nur maximal 12 Cent brutto pro Kilowattstunde für Gas bzw. 9,5 Cent brutto pro Kilowattstunde für Wärme zahlen. Dies gilt für bis zu 80 Prozent des im September 2022 prognostizierten Gasbedarfs für das Folgejahr. Der im jeweiligen Tarif darüberliegende Anteil am Arbeitspreis wird vom Bund übernommen. Dies finanziert er über Steuermittel sowie aus den Einnahmen der Abschöpfung der bei Energiekonzernen angefallenen Übergewinne/Zufallsgewinne. Für die restlichen 20 Prozent gilt der reguläre Preis, d.h. für diesen Anteil müssen Sie den in Ihrem Tarif geltenden Arbeitspreis zahlen. Das heißt aber auch: Sparen lohnt sich hier besonders!",
    state_funding_23_tooltip__waerme:
      "Die Wärme-Preisbremse gilt von März 2023 bis Dezember 2023, wird aber auch rückwirkend auf Januar und Februar angewendet. Private Haushalte sowie kleine Unternehmen mit einem Wärmeverbrauch bis maximal 1,5 Millionen Kilowattstunden im Jahr müssen so nur maximal 9,5 Cent brutto pro Kilowattstunde für Wärme zahlen. Dies gilt für bis zu 80 Prozent des im September 2022 prognostizierten Wärmebedarfs für das Folgejahr. Der im jeweiligen Tarif darüberliegende Anteil am Arbeitspreis wird vom Bund übernommen. Dies finanziert er über Steuermittel sowie aus den Einnahmen der Abschöpfung der bei Energiekonzernen angefallenen Übergewinne/Zufallsgewinne. Dies finanziert er über Steuermittel sowie aus den Einnahmen der Abschöpfung der bei Energiekonzernen angefallenen Übergewinne/Zufallsgewinne. Für die restlichen 20 Prozent gilt der reguläre Preis, d.h. für diesen Anteil müssen Sie den in Ihrem Tarif geltenden Arbeitspreis zahlen. Das heißt aber auch: Sparen lohnt sich hier besonders!",
    state_funding_23_factor: "Berechnen für...",
    state_funding_apply: "Soforthilfe miteinbeziehen",
    state_funding_total: "Unterstützungsbetrag",
    new_price_difference_after_state_funding: "Verbliebene Mehrkosten zum Vorjahr",
    saving_opportunities: "Ihre {{UNIT}}-Einsparmöglichkeiten",
    saving_opportunities_short: "Einsparmöglichkeiten",
    savings: "Einsparungen",
    savings_in_euro: "Einsparung in €",
    savings_in_kwh: "Einsparung in kWh",
    new_price_difference_after_saving_opportunities: "Verbliebene Mehrkosten zum Vorjahr nach Entlastungen und Einsparungen",
    new_price_after_saving_opportunities: "Neue {{UNIT}}-Jahreskosten",
    opportunity_total: "Mögliche Einsparsumme",
    opportunities: {
      one_degree: {
        label: "Wenn Sie die Raumtemperatur um {scale} °C senken...",
        description: "",
      },
      vent_radiators: {
        label: "Jährlich Heizkörper entlüften",
        description: "Nach längerem Betrieb oder Stillstand kann sich im Heizkreislauf Luft sammeln. Das ist nicht schlimm, erhöht aber den Verbrauch. Deshalb sollte man vor Beginn der Heizsaison entlüften. Das ist ganz einfach und schnell gemacht. Bei Problemen hilft Ihnen Ihr örtlicher Fachhandwerker sicher gerne weiter.",
      },
      maintenance: {
        label: "Jährliche Heizungswartung",
        description: "Gut gewartete Geräte erfreuen den Besitzer mehr und länger. Das gilt auch für Heizungen. Vor allem, weil nicht gewartete Heizungen in der Regel peu à peu mehr Energie als nötig verbauchen. Die regelmäßige Wartung durch einen Fachhandwerker hilft dem zu begegnen.",
      },
      clear_radiators: {
        label: "Heizkörper freistellen",
        description: "Heizkörper sind kein wirkliches Design-Highlight. Aber trotzdem sollten Sie diese nicht verstecken. Möbel, Vorhänge und Abdeckungen führen dazu, dass sich die abgegebene Wärme dahinter staut. Das heißt, der Raum braucht länger, um schön warm zu werden. Also: Lassen Sie Ihre Heizkörper sichtbar! Tipp: Möbel sollten mindestens 30 Zentimeter Abstand zur Heizung haben. Vorhänge am besten nicht bodenlang gestalten, sie sollten oberhalb des Heizkörpers enden. Von Heizkörperverkleidungen raten wir ab. Kleiner Hinweis: Heizkörpern regelmäßig mit dem Staubwedel zu Leibe rücken! Denn auch Staub und Flusen behindern eine effiziente Wärmeverteilung.",
      },
      insulate_heating_pipes: {
        label: "Heizrohre dämmen",
        description: "Ungedämmte, Heißwasser führende Rohre sind wahre Energieschleudern: Nur gedämmte Rohre reduzieren den Energieverlust auf ein Minimum. Da es sich hier schnell um Dutzende Meter handelt, rentieren sich Investitionen in diese Maßnahme besonders zügig.",
      },
      shutters: {
        label: "Rollläden/Vorhänge nachts schließen",
        description: "Rollläden und dicke Vorhänge sind, sofern runtergelassen/zugezogen, in gewissem Sinne eine zusätzliche Dämmschicht für die Fenster. Dadurch lassen diese noch schwerer als sonst die im Raum befindliche Wärme nach außen entweichen.",
      },
    },
  },
};

export const TEXT = TRANSLATIONS["de"];
