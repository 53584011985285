import { Fragment, h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { calcMonthlyStateFunding, fixTexts, IUnits } from "../../common";
import {
  Cards,
  formatEuro,
  formatGas,
  isMobile,
  parseNumber,
  RowCheckbox,
  RowEuro,
  RowInput,
  RowOpportunity,
  RowSelect,
} from "../../components/cards";
import { TEXT } from "../../texts/einsparrechner";

const urlParams = new URLSearchParams(window.location.search);

export function EinsparrechnerStrom() {
  return <Handler type="strom" />;
}
export function EinsparrechnerGas() {
  return <Handler type="gas" />;
}
export function EinsparrechnerWaerme() {
  return <Handler type="waerme" />;
}

function Handler({ type }: { type: IUnits }) {
  const ref = useRef<HTMLDivElement>(null);

  fixTexts(TEXT, type);

  useEffect(() => {
    try {
      const height = Math.round(
        ref.current?.getBoundingClientRect().height || 1000
      );

      if (window.top) {
        window.top.postMessage(
          { isEinsparrechnerMessage: true, type, height },
          "*"
        );
      }
    } catch (error) {
      console.error(error);
    }
  });

  const devMode = urlParams.get("dev") === "ja";

  const showStateFunding = true;
  const showStateFunding2022 =
    urlParams.get("soforthilfe_2022_ausblenden") !== "ja";

  const showOpportunities =
    urlParams.get("einsparoptionen_ausblenden") !== "ja";

  const [step, setStep] = useState(0);

  const [consumtion, setConsumtion] = useState(devMode ? "10000" : "");
  const [currentPriceTotalInput, setCurrentPriceTotalInput] = useState("");
  const [currentBasePrice, setCurrentBasePrice] = useState(devMode ? "10" : "");
  const [currentWorkingPrice, setCurrentWorkingPrice] = useState(
    devMode ? "5" : ""
  );
  const [nextBasePrice, setNextBasePrice] = useState(devMode ? "20" : "");
  const [nextWorkingPrice, setNextWorkingPrice] = useState(devMode ? "20" : "");
  const [stateFunding23Factor, setStateFunding23Factor] = useState("12");

  const [currentPriceTotalKnown, setCurrentPriceTotalKnown] =
    useState<boolean>(false);

  const [applyStateFundung, setApplyStateFunding] = useState<boolean>(false);

  const [opportunityChecks, setOpportunityChecks] = useState<
    Record<string, boolean>
  >({});

  const [opportunityScale, setOpportunityScale] = useState<
    Record<string, number>
  >({});

  // callbacks

  const nextDisabled =
    step === 0 &&
    (consumtion === "" ||
      (currentPriceTotalKnown
        ? currentPriceTotalInput === ""
        : currentBasePrice === "" || currentWorkingPrice === "") ||
      nextBasePrice === "" ||
      nextWorkingPrice === "");

  // calc:

  const consumtionNumber = parseNumber(consumtion);
  const currentPriceTotalInputNumber = parseNumber(currentPriceTotalInput);
  const currentBasePriceNumber = parseNumber(currentBasePrice);
  const currentWorkingPriceNumber = parseNumber(currentWorkingPrice) / 100;
  const nextBasePriceNumber = parseNumber(nextBasePrice);
  const nextWorkingPriceNumber = parseNumber(nextWorkingPrice) / 100;
  const stateFunding23FactorNumber = parseNumber(stateFunding23Factor);

  const currentPriceTotal = currentPriceTotalKnown
    ? currentPriceTotalInputNumber
    : consumtionNumber * currentWorkingPriceNumber + currentBasePriceNumber;

  const newPriceTotal =
    consumtionNumber * nextWorkingPriceNumber + nextBasePriceNumber;

  const newPriceDiff = newPriceTotal - currentPriceTotal;

  const stateFundingDec22 = showStateFunding2022 ? newPriceTotal / 12 : 0;

  const stateFunding23Monthly = calcMonthlyStateFunding(
    type,
    consumtionNumber,
    nextWorkingPriceNumber
  );

  const stateFunding23 = stateFunding23Monthly * stateFunding23FactorNumber;

  const stateFundingTotal =
    stateFunding23 + (applyStateFundung ? stateFundingDec22 : 0);

  const newPriceDiffAfterStateFunding = newPriceDiff - stateFundingTotal;

  // opportunities:

  const opportunities = {
    one_degree: {
      label: TEXT.opportunities.one_degree.label,
      description: TEXT.opportunities.one_degree.description,
      factor: 0.06,
      scale: true,
    },
    vent_radiators: {
      label: TEXT.opportunities.vent_radiators.label,
      description: TEXT.opportunities.vent_radiators.description,
      factor: 0.15,
      scale: false,
    },
    maintenance: {
      label: TEXT.opportunities.maintenance.label,
      description: TEXT.opportunities.maintenance.description,
      factor: 0.05,
      scale: false,
    },
    insulate_heating_pipes: {
      label: TEXT.opportunities.insulate_heating_pipes.label,
      description: TEXT.opportunities.insulate_heating_pipes.description,
      factor: 0.08,
      scale: false,
    },
    clear_radiators: {
      label: TEXT.opportunities.clear_radiators.label,
      description: TEXT.opportunities.clear_radiators.description,
      factor: 0.12,
      scale: false,
    },
    shutter: {
      label: TEXT.opportunities.shutters.label,
      description: TEXT.opportunities.shutters.description,
      factor: 0.06,
      scale: false,
    },
  };

  let steps = [
    {
      key: "base",
      title: TEXT.last_annual_statement,
      titleShort: TEXT.last_annual_statement_short,
      description: TEXT.description_step1,
      descriptionBeforeTitle: true,
    },
    {
      key: "statefunding",
      title: TEXT.card_statefunding_title,
      titleShort: TEXT.card_statefunding_title_short,
      description: TEXT.card_statefunding_description,
      descriptionBeforeTitle: false,
    },
    {
      key: "opportunities",
      title: TEXT.saving_opportunities,
      titleShort: TEXT.saving_opportunities_short,
      description: TEXT.description_step3,
      descriptionBeforeTitle: false,
    },
  ];

  if (!showStateFunding) {
    steps = steps.filter((s) => s.key !== "statefunding");
  }

  if (!showOpportunities) {
    steps = steps.filter((s) => s.key !== "opportunities");
  }

  const activeStep = steps[step];

  let consumptionAfterOpportunities = consumtionNumber;

  const opportunitySavings: Record<string, { gas: number; euro: number }> = {};

  for (const [key, { factor }] of Object.entries(opportunities)) {
    const checked = !!opportunityChecks[key];
    const scale = opportunityScale[key] || 1;

    let gas = 0;

    if (checked) {
      for (let i = 0; i < scale; i++) {
        const reduction = consumptionAfterOpportunities * factor;
        gas += Math.round(reduction);
        consumptionAfterOpportunities -= reduction;
      }
    }

    const euro = Math.round(gas * nextWorkingPriceNumber);

    opportunitySavings[key] = { gas, euro };
  }

  // opportunity total:

  const opportunityTotalSavingGas = Object.values(opportunitySavings)
    .map((s) => s.gas)
    .reduce((acc, current) => acc + current, 0);

  const opportunityTotalSavingEuro = Object.values(opportunitySavings)
    .map((s) => s.euro)
    .reduce((acc, current) => acc + current, 0);

  const newPriceDiffAfterOpportunities =
    newPriceDiff - opportunityTotalSavingEuro;

  const newPriceAfterOpportunities =
    newPriceDiffAfterOpportunities + currentPriceTotal;

  const newPriceDiffAfterOpportunitiesAndStateFunding =
    newPriceDiff - opportunityTotalSavingEuro - stateFundingTotal;

  return (
    <div class="iframe-default" ref={ref}>
      <Cards
        step={step}
        onStep={setStep}
        steps={steps}
        nextDisabled={nextDisabled}
        warning={
          consumtionNumber >= 1500000
            ? TEXT.current_consumtion_limit
            : undefined
        }
      >
        {activeStep?.key === "base" && (
          <>
            <RowInput
              label={TEXT.current_consumtion}
              tooltip={TEXT.current_consumtion_info}
              value={consumtion}
              onChange={setConsumtion}
            />
            {/* <RowCheckbox
            label={TEXT.current_price_total_known}
            value={currentPriceTotalKnown}
            onChange={setCurrentPriceTotalKnown}
          /> */}
            {currentPriceTotalKnown && (
              <Fragment>
                <RowInput
                  label={TEXT.current_price_total_euro}
                  value={currentPriceTotalInput}
                  onChange={setCurrentPriceTotalInput}
                />
              </Fragment>
            )}
            {!currentPriceTotalKnown && (
              <Fragment>
                <RowInput
                  label={TEXT.current_base_price}
                  value={currentBasePrice}
                  onChange={setCurrentBasePrice}
                />
                <RowInput
                  label={TEXT.current_working_price}
                  value={currentWorkingPrice}
                  onChange={setCurrentWorkingPrice}
                />
                <RowEuro
                  label={TEXT.current_price_total}
                  euro={formatEuro(currentPriceTotal)}
                />
              </Fragment>
            )}

            {!isMobile() && <div style={{ height: "var(--lineHeight)" }}></div>}

            <div class="card-header">
              <div class="card-title">{TEXT.new_price}</div>
            </div>

            <RowInput
              label={TEXT.new_base_price}
              value={nextBasePrice}
              onChange={setNextBasePrice}
            />
            <RowInput
              label={TEXT.new_working_price}
              value={nextWorkingPrice}
              onChange={setNextWorkingPrice}
            />
            <RowEuro
              label={TEXT.new_price_total}
              euro={formatEuro(newPriceTotal)}
            />

            {/* {showStateFunding && (
              <RowEuro
                label={TEXT.state_funding_dec22}
                tooltip={TEXT.state_funding_dec22_tooltip}
                euro={formatEuro(stateFundingDec22)}
                positive
                ca
              />
            )} */}

            <RowEuro
              label={TEXT.new_price_difference}
              euro={formatEuro(newPriceDiff)}
              negative
            />

            {/* <RowEuro
              label={TEXT.new_price_difference_after_state_funding}
              euro={newPriceDiffAfterStateFunding}
            /> */}
          </>
        )}

        {activeStep?.key === "statefunding" && (
          <>
            {/* <RowEuro
              label={TEXT.new_price_total}
              euro={formatEuro(newPriceTotal)}
            /> */}

            <RowEuro
              label={TEXT.new_price_difference}
              euro={formatEuro(newPriceDiff)}
              negative
            />

            {showStateFunding2022 && (
              <>
                {!isMobile() && (
                  <div style={{ height: "var(--lineHeight)" }}></div>
                )}

                <div class="card-header">
                  <div class="card-title">{TEXT.state_funding_dec22}</div>
                </div>

                <RowEuro
                  label={TEXT.state_funding_dec22}
                  tooltip={TEXT.state_funding_dec22_tooltip}
                  euro={formatEuro(stateFundingDec22)}
                  prefix={TEXT.ca}
                  positive
                />

                <RowCheckbox
                  label={TEXT.state_funding_apply}
                  value={applyStateFundung}
                  onChange={setApplyStateFunding}
                />
              </>
            )}

            {!isMobile() && <div style={{ height: "var(--lineHeight)" }}></div>}

            <div class="card-header">
              <div class="card-title">{TEXT.card_statefunding_title_short}</div>
            </div>

            <RowEuro
              label={TEXT.state_funding_23}
              tooltip={TEXT.state_funding_23_tooltip}
              euro={formatEuro(stateFunding23Monthly)}
              prefix={TEXT.ca}
              suffix={TEXT.per_month}
              positive
            />

            <RowSelect
              label={TEXT.state_funding_23_factor}
              value={stateFunding23Factor}
              onChange={setStateFunding23Factor}
              options={Array(12)
                .fill("")
                .map((_, i) => (i + 1).toString())
                .map((value) => ({
                  value,
                  label: value === "1" ? "Einen Monat" : `${value} Monate`,
                }))}
            />

            {!isMobile() && <div style={{ height: "var(--lineHeight)" }}></div>}

            <RowEuro
              label={TEXT.state_funding_total}
              euro={formatEuro(stateFundingTotal)}
              prefix={TEXT.ca}
              positive
              primary
            />

            <RowEuro
              label={TEXT.new_price_difference_after_state_funding}
              euro={formatEuro(newPriceDiffAfterStateFunding)}
              negative
            />
          </>
        )}

        {activeStep?.key === "opportunities" && (
          <>
            {showStateFunding && (
              <RowEuro
                label={TEXT.new_price_difference}
                euro={formatEuro(newPriceDiffAfterStateFunding)}
                negative
              />
            )}

            {Object.entries(opportunities).map(
              ([key, { label, description, scale }]) => {
                return (
                  <RowOpportunity
                    label={label}
                    description={description}
                    euro={formatEuro(opportunitySavings[key].euro)}
                    kWh={formatGas(opportunitySavings[key].gas)}
                    checked={opportunityChecks[key]}
                    onCheck={(checked) =>
                      setOpportunityChecks((current) => ({
                        ...current,
                        [key]: checked,
                      }))
                    }
                    scale={(scale && opportunityScale[key]) || 1}
                    onScale={
                      scale
                        ? (value) =>
                            setOpportunityScale((current) => ({
                              ...current,
                              [key]: value,
                            }))
                        : undefined
                    }
                  />
                );
              }
            )}

            <RowOpportunity
              label={TEXT.opportunity_total}
              euro={formatEuro(opportunityTotalSavingEuro)}
              kWh={formatGas(opportunityTotalSavingGas)}
            />

            {/* <RowEuro
              label={TEXT.new_price_difference_after_saving_opportunities}
              euro={formatEuro(newPriceDiffAfterOpportunities)}
            /> */}

            {/* <RowEuro
              label={TEXT.new_price_after_saving_opportunities}
              euro={formatEuro(newPriceAfterOpportunities)}
            /> */}
            <RowEuro
              label={TEXT.new_price_difference_after_saving_opportunities}
              euro={formatEuro(newPriceDiffAfterOpportunitiesAndStateFunding)}
              multiline
              positive={newPriceDiffAfterOpportunitiesAndStateFunding < 0}
              negative={newPriceDiffAfterOpportunitiesAndStateFunding > 0}
            />
          </>
        )}
      </Cards>
    </div>
  );
}
