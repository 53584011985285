const TRANSLATIONS = {
  de: {
    euro: "€",
    unit: "kWh",
    next: "Weiter",
    back: "Zurück",
    brutto: "Brutto",
    netto: "Netto",
    ca: "ca. ",
    per_month: " pro Monat",

    last_annual_statement: "Ihre letzte Strom-Abrechnung",
    last_annual_statement_short: "Abrechnung & Verbrauch",
    last_annual_statement_description:
      "Mit unserem Strom-Einsparrechner kalkulieren Sie anhand weniger Basisdaten die Höhe Ihrer Stromkosten im laufenden Jahr und können die Auswirkungen ausgewählter Sparmaßnahmen erproben.<br><b>Hinweis:</b> Alle Beträge sind Brutto-Beträge. Bitte beachten Sie, dass die errechneten Beträge unverbindlich sind und lediglich der Orientierung dienen.",

    card_statefunding_title: "Unterstützung durch Strom-Preisbremse",
    card_statefunding_title_short: "Strom-Preisbremse",
    card_statefunding_description:
      "Zur Abmilderung der gestiegenen Strompreise hat die Bundesregierung verschiedene Maßnahmen beschlossen. Eine davon ist die sogenannte Strom-Preisbremse. Diese sorgt dafür, dass ein großer Anteil des benötigten Stromes bis Dezember 2023 für Kundinnen und Kunden nur zu einem niedrigeren Betrag bezogen wird. Die Differenz zum eigentlichen Tarifpreis übernimmt der Staat. Folgend zeigen wir Ihnen, wie sich die Strom-Preisbremse bei Ihnen auswirkt. Bitte beachten Sie, dass es sich bei den Beträgen um Näherungswerte auf Basis des letzten Verbrauchs handelt. Diese können vom tatsächlichen Wert abweichen.",

    current_consumtion: "Ihr letzter Verbrauch (kWh / Jahr)",
    current_consumtion_info:
      "Um den exakten Wert für die Entlastung im Bereich Strom zu berechnen, benötigen wir den aktuellen Prognosewert. Dieser Wert ist von Kunde zu Kunde und von Region zu Region unterschiedlich. Aus Gründen der Vereinfachung haben wir uns entschieden, hier als Näherungswert den letztjährigen Verbrauch anzulegen. Aus diesem Grund können die tatsächlichen Werte leicht abweichen.",
    current_consumtion_limit:
      "Bitte beachten Sie, dass der Rechner nur bis zu einem Stromverbrauch von 30.000 kWh gilt. Bei höheren Verbräuchen gelten die Regelungen für Vielverbraucher und Unternehmen.",
    current_base_price: "Ihr letzter Grundpreis (€ / Jahr)",
    current_working_price: "Ihr letzter Arbeitspreis (ct / kWh)",
    current_price_total: "Ihre letztjährigen Strom-Kosten",
    current_price_total_euro: "Ihre letztjährigen Strom-Kosten (€)",
    current_price_total_known: "Kennen Sie Ihre letztjährigen Strom-Kosten?",
    new_price: "Preise nach Preisanpassung",
    new_base_price: "Aktueller Grundpreis (€ / Jahr)",
    new_working_price: "Aktueller Arbeitspreis (ct / kWh)",
    new_price_total: "Neue Strom-Jahreskosten",
    new_price_difference: "Mehrkosten zum Vorjahr",

    price_total_with_statefunding: "Jahreskosten mit Förderung",
    price_total_without_statefunding: "Jahreskosten ohne Förderung",

    state_funding_dec22: "Dezember-Soforthilfe",
    state_funding_dec22_tooltip:
      "Es handelt sich um einen Näherungswert, konkret ein Zwölftel der Verbrauchsprognose für das kommende Jahr. Exakt ergibt sich der Wert aus der „Multiplikation von einem Zwölftel des Jahresverbrauchs, den der Stromlieferant für die Entnahmestelle im Monat September 2022 prognostiziert hat, mit dem Arbeitspreis in Cent pro Kilowattstunde, der zum Stichtag 1. Dezember für den Monat Dezember 2022 im jeweiligen Lieferverhältnis vereinbart ist“. Diese Entlastung wird für den Monat Dezember 2022 gutgeschrieben und spätestens in ihrer Jahresabrechnung berücksichtigt. Hinweis: Der Prognosewert entspricht nicht unbedingt dem Wert des Verbrauchs der letzten Abrechnung.",
    state_funding_23: "Staatliche Entlastung",
    state_funding_23_tooltip:
      "Die Strom-Preisbremse gilt von März 2023 bis Dezember 2023, wird aber auch rückwirkend auf Januar und Februar angewendet. Private Haushalte sowie Kleingewerbe müssen 40 Cent Brutto pro Kilowattstunde Strom zahlen. Dies gilt für bis zu 80 Prozent des im September 2022 prognostizierten Strombedarfs für das Folgejahr. Der im jeweiligen Tarif darüberliegende Anteil am Arbeitspreis wird vom Bund übernommen. Dies finanziert er über Steuermittel sowie aus den Einnahmen der Abschöpfung der bei Energiekonzernen angefallenen Übergewinne/Zufallsgewinne. Für die restlichen 20 Prozent gilt der reguläre Preis, d.h. für diesen Anteil müssen Sie den in Ihrem Tarif geltenden Arbeitspreis zahlen. Das heißt aber auch: Sparen lohnt sich hier besonders!",
    state_funding_23_factor: "Berechnen für...",
    state_funding_apply: "Soforthilfe miteinbeziehen",
    state_funding_total: "Unterstützungsbetrag",
    new_price_difference_after_state_funding:
      "Verbliebene Mehrkosten zum Vorjahr",
    saving_opportunities: "Ihre Strom-Einsparmöglichkeiten",
    saving_opportunities_short: "Einsparmöglichkeiten",
    saving_opportunities_description:
      "Bei den folgenden Einsparungen durch Maßnahmen handelt es sich i.d.R. um Durchschnittswerte, die auf langjähriger Erfahrung beruhen. Diese können jedoch im Einzelfall, je nach individuellem energetischem Zustand Ihrer Wohnung/Ihres Hauses, abweichen. Bedenken Sie zudem: Den größten Spar-Erfolg erzielen Sie meist dort, wo Sie bislang noch keine Sparanstrengungen unternommen haben! Für die konkret errechnete Summe können wir deshalb keine Gewähr übernehmen. Es handelt sich um Näherungswerte.",
    savings: "Einsparungen",
    savings_in_euro: "Einsparung in €",
    savings_in_kwh: "Einsparung in kWh",
    new_price_difference_after_saving_opportunities:
      "Verbliebene Mehrkosten zum Vorjahr nach Entlastungen und Einsparungen",
    new_price_after_saving_opportunities: "Neue Strom-Jahreskosten",
    opportunity_total: "Mögliche Einsparsumme",
    opportunities: {
      uwp: {
        label: "Umwälzpumpe erneuern",
        description:
          "Wenn das Wasser in Ihrem Eigenheim zentral erwärmt wird, ist die Wahrscheinlichkeit sehr hoch, dass ein großes Stromspar-Potenzial im Heizungskeller liegt. Falls Ihre Pumpe in die Jahre gekommen ist, sollten Sie mit einem Austausch nicht so lange warten, bis die alte Pumpe nicht mehr funktioniert. Modernere und hocheffiziente Pumpen verbrauchen 70-80 % weniger Strom. Sie rechnen sich daher bereits innerhalb weniger Jahre. Da bis zur Lieferung und dem Einbau einer neuen Pumpe etwas Zeit vergeht, können Sie in der Zwischenzeit auch durch andere Maßnahmen Strom sparen. Zunächst einmal sollten Sie prüfen, ob Sie die Leistung Ihrer Pumpe auf eine niedrigere Stufe einstellen können. In der Regel transportiert die Umwälzpumpe auch mit niedriger eingestellter Leistung noch genügend heißes Wasser vom Heizkessel zu den Heizkörpern. Falls dort nicht mehr ausreichend Wärme ankommt, kann man einfach die nächsthöhere Leistungsstufe ausprobieren.<br><b>Hinweis:</b> Das Bundeswirtschaftsministerium unterstützt den Austausch alter Heizungspumpen gegen effiziente Modelle mit der „Förderung der Heizungsoptimierung durch hocheffiziente Pumpen und hydraulischen Abgleich“. Das Förderprogramm erstattet 20 Prozent der Nettokosten. Wichtig: Der Antrag auf Förderung muss vor dem Austausch gestellt werden.",
      },
      standby: {
        label: "Standby-Gerät ausschalten",
        description:
          "Die meisten Elektronikgeräte im Haushalt werden nur wenige Stunden am Tag betrieben. Doch auch in der restlichen Zeit verbrauchen sie im Standby-Modus Energie. Dieser Verbrauch wird auch Leerlaufverlust genannt. In Deutschland sind jährlich schätzungsweise vier Milliarden Euro Stromkosten darauf zurückzuführen. Sie können also einiges sparen, wenn Sie z.B. folgende Verbraucher, die nicht in Gebrauch sind, vom <b>Stromnetz trennen: TV, TV-Receiver, DVD-Player, Radios, PC mit Monitor und Drucker, Internet-Router.</b>",
      },
      warmwater: {
        label: "Einsparungen bei Warmwasser (bei Durchlauferhitzern)",
        description:
          "Wie viel Energie zum Erzeugen von warmem Wasser benötigt wird, hängt vor allem von der Verbrauchsmenge und der Warmwasserbereitung ab. Hier sind ein paar Tipps, wie Sie den Energieverbrauch bei der Verwendung eines Durchlauferhitzers senken können. Hinweis: Diese Tipps gelten natürlich auch für andere Formen der Warmwasseraufbereitung. Hände mit kaltem Wasser waschen, Anschaffung eines Sparduschkopfes (verbraucht bis zu 50% weniger Wasser), Duschen statt Baden, kürzer Duschen, Temperatur des Durchlauferhitzers verringern.",
        scale_unit: "Anzahl Personen",
      },
      devices: {
        label: "Alte Haushaltsgeräte austauschen ",
        description:
          "Wenn Ihr Stromverbrauch zu hoch ist, tragen eine Mitschuld daran wahrscheinlich große, ineffiziente Haushaltsgeräte. Wie alt sind beispielsweise Ihr Kühlschrank und Ihr Geschirrspüler? Hier sind  einige Bespiele, welche Geräte viel Strom verbrauchen können: <b>Geschirrspüler, Waschmaschine, Wäschetrockner, Kühl- & Gefrierschrank, Herd & Backofen.</b>",
        scale_unit: "Anzahl ausgetauschter Haushaltsgeräte",
      },
    },
  },
};

export const TEXT = TRANSLATIONS["de"];
